<template>
    <v-hover v-slot:default="{hover}">
        <v-card
            class="card off mx-2 mb-1" :class="{ 'card_hover' : hover}"
            :elevation="hover ? 4 : 2" style="cursor: pointer;">
            <v-card-text class="card-state pa-1" :class="item.state">
                <!--HEADER-->
                <v-row no-gutters justify="center" v-if="false">
                    <v-col cols="12" class="pb-0" :md="grid.md">
                        <span class="font-weight-bold">{{ item.date }}</span>
                    </v-col>
                    <v-col cols="12" :md="grid.md" class="font-weight-bold"></v-col>
                    <v-col cols="12">
                        <v-divider class="gold lighten-1"></v-divider>
                    </v-col>
                </v-row>

                <!--DATA-->
                <v-row justify="center" align="center" class="" no-gutters>
                    <v-row v-if="true" align="center" no-gutters class="headline" style="font-size: 1.2em !important;">
                        <v-col v-bind="layout" v-for="v in keys" :key="v.value">
                            <v-row no-gutters align="center" v-if="showColumn(v.value)">
                                <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys(v.value) }}</v-col>
                                <v-col cols="12" class="gold--text" :class="v.value === 'date' ? 'font-weight-bold' : ''">{{ g_isDefined(item[v.value]) }}</v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-row>

                <!--FOOTER-->
                <v-row v-if="false" justify="center" align="center" no-gutters>
                    <v-row no-gutters align="center" class="pa-0 ma-0 rounded">
                        <v-icon size="20" class="px-1">mdi-account-tie</v-icon>
                        <span class="caption">{{ item.seller }}</span>
                    </v-row>
                </v-row>
            </v-card-text>

        </v-card>
    </v-hover>
</template>

<script>
export default {
    name: "SalaryCard",
    props: {
        item: Object,
        keys: undefined,
        state: undefined
    },
    data: () => ({
        grid: {sm: 6, md: 6, lg: 2},
        card: { cols: 6, xs: 6, sm: 4, md: 1, xl: 0, align: 'center', class: 'my-1 full-cols full-cols-5'}
    }),
    computed: {
        layout() {
            return this.card;
        }
    },
    methods: {
        showColumn(column) {
            if (column === 'paid') {
                if (this.item.amount === "- - -" || this.item.amount === '0,00 €') {
                    return false
                }
            }
            return true
        }
    }
}
</script>

<style>
.card .keys .col-md-0 {
    flex: unset;
    width: calc(100% / 5);
}
</style>